var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: _vm.styles.wrapper, attrs: { id: "wrapper" } },
    [
      _c("p", {
        staticClass: "mb-0 font-weight-bold text-center",
        style: _vm.styles.title,
        domProps: { innerHTML: _vm._s(_vm.title) },
      }),
      _c("p", {
        staticClass: "mb-0 subtitle text-center",
        style: _vm.styles.subtitle,
        domProps: { innerHTML: _vm._s(_vm.subtitle) },
      }),
      _c("divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }