var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-center yellow darken-1",
      style: _vm.styles.wrapper,
      attrs: { id: "wrapper" },
    },
    [
      _c("p", { style: _vm.styles.text, attrs: { id: "text" } }, [
        _vm._v(" Tuttiについて"),
        !_vm.$vuetify.breakpoint.forPC ? _c("br") : _vm._e(),
        _vm._v("詳しく知りたい方はこちら "),
      ]),
      _c("contact-button", { style: _vm.styles.button }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }